


































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import BackIcon from '@/app/ui/assets/icon_back.vue'
import Button from '@/app/ui/components/Button/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import ModalSuccess from '@/app/ui/views/ManageUserAndCourier/components/ModalSuccess/index.vue'
import ModalFailed from '@/app/ui/views/ManageUserAndCourier/components/ModalFailed/index.vue'
import ModalConfirm from '@/app/ui/views/ManageUserAndCourier/components/ModalConfirm/index.vue'
import ModalUploadBulky from '@/app/ui/views/ManageUserAndCourier/components/ModalUploadBulky/index.vue'
import EmptyIllustration from '@/app/ui/assets/EmptyState/no_data-paket.vue'
import EmptyFilterIllustration from '@/app/ui/assets/EmptyState/no_filtered_data.vue'
import ErrorIcon from '@/app/ui/assets/illustration_confirmation_delete.vue'
import WarningIcon from '@/app/ui/assets/icon_warning_circle.vue'
import SuccessIcon from '@/app/ui/assets/success_icon_alt.vue'
import DownloadIcon from '@/app/ui/assets/icon_download_filled.vue'
import {
  EnumAlertMessage,
  EnumInvalidReason,
  EnumErrorUploadBulk,
  EnumStatusUpload,
  EnumManageCourierRoute,
} from '@/app/infrastructures/misc/Constants/manageCourier'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import controller from '@/app/ui/controllers/ManageCourierController'
import {
  ManageCourierUploadBulkRow,
} from '@/domain/entities/ManageCourier'
import { IFileData, IHeaderCell, IOptions, ITableCell } from '@/data/infrastructures/misc/interfaces/manageCourier'

interface ManageCourierUploadBulk {
  dataUpload?: {
    filename?: string
    filepath?: string
    rows?: ManageCourierUploadBulkRow[]
    pagination?: {
      page: number
      perPage: number
      totalItem: number
    }
    summary?: {
      rowValid: number
      rowInvalid: number
    }
  }
}

@Component({
  components: {
    Button,
    BackIcon,
    DataTableV2,
    PaginationNav,
    EmptyIllustration,
    EmptyFilterIllustration,
    DropdownSelect,
    ModalUploadBulky,
    Modal,
    ErrorIcon,
    ModalSuccess,
    ModalConfirm,
    ModalFailed,
    WarningIcon,
    SuccessIcon,
    DownloadIcon,
    LoadingOverlay,
  },
})
export default class ManageCourierUploadBulkyPage extends Vue {
  controller = controller
  enumAlertMessage = EnumAlertMessage
  enumInvalidReason = EnumInvalidReason
  enumManageCourierRoute = EnumManageCourierRoute
  enumErrorUploadBulk = EnumErrorUploadBulk

  modalUploadBulkyVisible = false
  modalConfirmReuploadVisible = false
  modalConfirmReturnVisible = false
  modalFailedConnectionVisible = false
  modalFailedNameExistVisible = false
  modalSuccessVisible = false
  modalFailedInvalidFileVisible = false

  paginationOptions: IOptions[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  statusOptions: IOptions[] = [
    { label: 'Semua', value: '' },
    { label: 'Valid', value: 'VALID' },
    { label: 'Tidak Valid', value: 'INVALID' },
  ]

  parameters = {
    id: NaN,
    page: 1,
    perPage: 10,
    status: this.statusOptions[0],
  }

  headers = [
    this.headerCellMapper('Baris', '76px'),
    this.headerCellMapper('ID Kurir', '120px'),
    this.headerCellMapper('Nama Kurir', '140px'),
    this.headerCellMapper('Tanggal Cuti/Libur', '250px'),
    this.headerCellMapper('Jam Cuti', '250px'),
    this.headerCellMapper('Status', '200px'),
  ]

  fileData: IFileData = {
    filename: '',
    filepath: '',
    rowValid: NaN,
    rowInvalid: NaN,
    courierValid: NaN,
    courierInvalid: NaN,
  }

  tableData: Array<Array<string | number | ITableCell>> = []

  created(): void {
    if (
      this.$route.name === this.enumManageCourierRoute.UPLOAD_BULKY_LIST_PAGE ||
      this.$route.name ===
        this.enumManageCourierRoute.HISTORY_UPLOAD_BULKY_DETAIL_PAGE
    ) {
      this.fetchUploadBulkData(true)
    }
  }

  mounted(): void {
    this.onSetDisplayBreadcrumb(false)
    window.addEventListener('offline', this.onHandleOffline)
  }

  get isAllValid(): boolean {
    return Boolean(this.fileData.rowInvalid === 0)
  }

  get params(): Record<string, string | number> {
    return {
      id: this.parameters.id,
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      filter: this.parameters.status.value,
    }
  }

  get isHistoryUploadDetail(): boolean {
    return (
      this.$route.name ===
      this.enumManageCourierRoute.HISTORY_UPLOAD_BULKY_DETAIL_PAGE
    )
  }

  get buttonBackText(): string {
    return this.isHistoryUploadDetail ? 'Kembali' : 'Kembali ke Jadwal Kurir'
  }

  // get linkSourceFile(): string {
  //   return controller.dataUploadBulky.dataUpload?.filepath || ''
  // }

  private fetchUploadBulkData(reset?: boolean): void {
    if (reset) {
      this.parameters.page = 1
    }
    if (
      this.$route.name === this.enumManageCourierRoute.UPLOAD_BULKY_LIST_PAGE
    ) {
      controller.getListUploadBulk(this.params)
    } else {
      this.parameters.id = <never>this.$route.params.id
      controller.getManageCourierDetailHistoryUploadBulk(this.params)
    }
  }

  private onHandleOffline(): void {
    if (this.modalUploadBulkyVisible) {
      this.modalUploadBulkyVisible = false
      this.modalFailedConnectionVisible = true
    }
  }

  private tableDataMapper(data: ManageCourierUploadBulkRow[]): ITableCell[][] {
    return data.map((data, index) => [
      this.tableCellMapper(
        (this.parameters.page - 1) * this.parameters.perPage + index + 1,
        '76px'
      ),
      this.tableCellMapper(data.courierId || '', '120px'),
      this.tableCellMapper(data.fullName || '', '140px'),
      this.tableCellMapper(
        data.dateLeave
          ? this.checkInvalidDate(data.dateLeave)
            ? data.dateLeave
            : Utils.formatDateWithIDLocale(data.dateLeave, 'DD MMMM YYYY')
          : '',
        '250px'
      ),
      this.tableCellMapper(
        `${
          data.startTime
            ? this.checkInvalidDate(data.startTime)
              ? data.startTime
              : Utils.formatDateWithIDLocale(data.startTime, 'HH:mm')
            : ''
        } - ${
          data.endTime
            ? this.checkInvalidDate(data.endTime)
              ? data.endTime
              : Utils.formatDateWithIDLocale(data.endTime, 'HH:mm')
            : ''
        }`,
        '250px'
      ),
      this.tableCellMapper(data.reason || data.status || '', '200px'),
    ])
  }

  private checkInvalidDate(value: string): boolean {
    const dateString = new Date(value).toString()

    if (dateString === 'Invalid Date') {
      return true
    }

    return false
  }

  private onSetDisplayBreadcrumb(isDisplayed: boolean): void {
    const breadcrumb = document.getElementById('breadcrumb')
    if (breadcrumb) {
      if (isDisplayed) {
        breadcrumb.style.display = 'flex'
      } else {
        breadcrumb.style.display = 'none'
      }
    }
  }

  private onBack(): void {
    if (this.isHistoryUploadDetail) {
      this.$router.push({
        name: this.enumManageCourierRoute.HISTORY_UPLOAD_BULKY_LIST_PAGE,
      })
    } else {
      this.modalConfirmReturnVisible = true
    }
  }

  // private onDownloadFile(): void {
  //   if (this.linkSourceFile)
  //     window.open(
  //       process.env.VUE_APP_STORAGE_BASE_URL + '/' + this.linkSourceFile
  //     )
  // }

  private onSubmit(): void {
    if (navigator.onLine) {
      controller.submitBulk()
    } else {
      this.modalFailedConnectionVisible = true
    }
  }

  private onFailedUpload(): void {
    this.modalFailedConnectionVisible = false
    this.onResetState()
  }

  private onChangePaginationOption(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchUploadBulkData(true)
  }

  private onClickReupload(isAllValid: boolean): void {
    if (isAllValid) {
      this.modalConfirmReuploadVisible = true
    } else {
      this.onConfirmReupload()
    }
  }

  private onConfirmReturn(): void {
    this.$router.push({ name: this.enumManageCourierRoute.MANAGE_COURIER_PAGE })
  }

  private onConfirmReupload(): void {
    if (
      this.$route.name ===
      this.enumManageCourierRoute.UPLOAD_BULKY_LIST_PAGE
    ) {
      this.$router.push({
        name: this.enumManageCourierRoute.UPLOAD_BULKY_PAGE,
      })
    }

    this.modalConfirmReuploadVisible = false
    this.modalFailedNameExistVisible = false
    this.modalFailedInvalidFileVisible = false
    this.onResetState()
  }

  private onResetState(): void {
    this.tableData = []
    this.fileData = {
      filename: '',
      filepath: '',
      rowValid: NaN,
      rowInvalid: NaN,
      courierValid: NaN,
      courierInvalid: NaN,
    }
    this.parameters = {
      id: NaN,
      page: 1,
      perPage: 10,
      status: this.statusOptions[0],
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    paddingRight?: string,
    paddingLeft?: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        paddingRight: paddingRight || '0px',
        paddingLeft: paddingLeft || '20px',
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string,
    paddingLeft?: string,
    paddingRight?: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
        paddingRight: paddingRight || '0px',
        paddingLeft: paddingLeft || '24px',
      },
    }
  }

  @Watch('controller.dataUploadBulky')
  setDataUploadBulky(data: ManageCourierUploadBulk): void {
    if (data.dataUpload) {
      const rows = data.dataUpload.rows
      const pagination = data.dataUpload.pagination

      this.fileData = {
        ...this.fileData,
        filename: <string>data.dataUpload.filename,
        filepath: <string>data.dataUpload.filepath,
        rowValid: <number>data.dataUpload.summary?.rowValid,
        rowInvalid: <number>data.dataUpload.summary?.rowInvalid,
      }

      if (rows) {
        this.tableData = this.tableDataMapper(
          rows.slice(0, this.parameters.perPage)
        )
      }

      if (pagination) {
        controller.setManageCourierUploadBulkyPagination(pagination)
      } else {
        if (rows) {
          controller.setManageCourierUploadBulkyPagination({
            page: this.parameters.page,
            perPage: this.parameters.perPage,
            totalItem: rows.length,
          })
        }
      }
    }
  }

  @Watch('controller.errUploadBulky')
  onCheckErrUploadBulky(msg: string): void {
    if (msg !== '') {
      if (msg === EventBusConstants.UPLOAD_BULKY_MANAGE_COURIER_LEAVE) {
        Utils.debounce(() => {
          controller.setStatusUpload(EnumStatusUpload.START)
          this.modalUploadBulkyVisible = false
          this.$router.push({
            name: this.enumManageCourierRoute.UPLOAD_BULKY_LIST_PAGE,
          })
          controller.setErrUploadBulky('')
        }, 400)()
      } else if (msg === this.enumErrorUploadBulk.INVALID_FILE) {
        controller.setStatusUpload(EnumStatusUpload.START)
        this.modalUploadBulkyVisible = false
        this.modalFailedInvalidFileVisible = true
      } else if (msg === this.enumErrorUploadBulk.OFFLINE) {
        controller.setStatusUpload(EnumStatusUpload.START)
        this.modalUploadBulkyVisible = false
        this.modalFailedConnectionVisible = true
      } else {
        Utils.debounce((response: string) => {
          controller.setStatusUpload(EnumStatusUpload.START)
          if (response.includes('is already uploaded')) {
            this.modalUploadBulkyVisible = false
            this.modalFailedNameExistVisible = true
          }
        }, 400)(msg)
      }
    }
  }

  @Watch('controller.errSubmitBulky')
  onCheckErrSubmitBulky(msg: string): void {
    if (msg === EventBusConstants.SUBMIT_BULKY_MANAGE_COURIER_LEAVE) {
      this.modalSuccessVisible = true
    }
    controller.setErrSubmitBulky('')
  }

  beforeDestroy(): void {
    this.onSetDisplayBreadcrumb(true)
    window.removeEventListener('offline', this.onHandleOffline)
  }
}
