








































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import FileUpload from '@/app/ui/components/FileUpload/index.vue'
import CloseLine from '@/app/ui/assets/close_icon_line.vue'
import controller from '@/app/ui/controllers/ManageCourierController'
import { EnumErrorUploadBulk, EnumStatusUpload } from '@/app/infrastructures/misc/Constants/manageCourier'

@Component({
  components: {
    Modal,
    Button,
    CloseLine,
    FileUpload
  },
})

export default class ModalUploadBulky extends Vue {
  @Prop({ default: false }) private visible!: boolean

  controller = controller
  filename = ''
  status = ''
  enumErrorUploadBulk = EnumErrorUploadBulk

  get isProcessing(): boolean {
    return controller.statusUpload === EnumStatusUpload.PROCESSING
  }

  get isFileInvalid(): boolean {
    return controller.errUploadBulky === this.enumErrorUploadBulk.INVALID_FILE
  }

  private onInputFileBulkUpload(file: File) {
    this.filename = file.name
    controller.uploadBulky({ file })
  }
}
