function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col gap-4 text-sm pt-4"},[_c('div',{staticClass:"flex justify-between items-center"},[_c('button',{staticClass:"button-back",on:{"click":_vm.onBack}},[_c('BackIcon'),_c('span',[_vm._v(_vm._s(_vm.buttonBackText))])],1),(!_vm.isHistoryUploadDetail)?_c('div',{staticClass:"flex gap-2 upload-buttons"},[_c('Button',{staticClass:"reupload-button",attrs:{"buttonStyle":"outline","disabled":_vm.tableData.length === 0},on:{"click":function () { return _vm.onClickReupload(_vm.isAllValid); }}},[_c('span',[_vm._v("Upload Ulang")])]),_c('Button',{staticClass:"submit-button",attrs:{"disabled":_vm.tableData.length === 0 || !_vm.isAllValid},on:{"click":_vm.onSubmit}},[_c('span',[_vm._v("Kirim Data")])])],1):_vm._e()]),(
      _vm.tableData.length !== 0 &&
        _vm.$route.name !==
          _vm.enumManageCourierRoute.HISTORY_UPLOAD_BULKY_DETAIL_PAGE
    )?_c('div',{class:['page-alert', _vm.isAllValid ? 'valid' : 'invalid']},[_c('div',{staticClass:"flex items-center gap-4"},[(_vm.isAllValid)?_c('SuccessIcon',{attrs:{"fill":"#006644","width":"24","height":"24"}}):_c('WarningIcon'),_c('span',[_vm._v(_vm._s(_vm.isAllValid ? _vm.enumAlertMessage.VALID : _vm.enumAlertMessage.INVALID.replace('###', _vm.fileData.rowInvalid)))])],1)]):_vm._e(),_c('div',{staticClass:"top-summary",class:{
      'mt-7':
        _vm.$route.name ===
        _vm.enumManageCourierRoute.HISTORY_UPLOAD_BULKY_DETAIL_PAGE,
    }},[_c('div',{staticClass:"top-summary-section col-span-1 border-r-0 p-4"},[_c('div',{staticClass:"top-summary-item"},[_c('span',{staticClass:"font-bold"},[_vm._v("Nama File:")]),_c('span',[_vm._v(_vm._s(_vm.fileData.filename || '-'))])])]),_c('div',{staticClass:"top-summary-section col-span-2 p-4"},[_c('div',{staticClass:"grid grid-cols-3"},[_c('div',{staticClass:"top-summary-item"},[_c('span',{staticClass:"font-bold"},[_vm._v("Total Baris Valid:")]),_c('span',[_vm._v(_vm._s(isNaN(_vm.fileData.rowValid) ? '-' : _vm.fileData.rowValid.toLocaleString('id-ID')))])]),_c('div',{staticClass:"top-summary-item"},[_c('span',{staticClass:"font-bold"},[_vm._v("Total Baris Tidak Valid:")]),_c('span',[_vm._v(_vm._s(isNaN(_vm.fileData.rowInvalid) ? '-' : _vm.fileData.rowInvalid.toLocaleString('id-ID')))])])])])]),(_vm.$route.name === _vm.enumManageCourierRoute.UPLOAD_BULKY_LIST_PAGE)?_c('div',{staticClass:"flex flex-col self-end w-60 gap-2"},[_c('span',{staticClass:"font-semibold"},[_vm._v("Filter Status")]),_c('DropdownSelect',{attrs:{"allowEmpty":false,"clearOnSelect":false,"searchable":false,"options":_vm.statusOptions},on:{"input":function () {
          _vm.fetchUploadBulkData(true)
        }},model:{value:(_vm.parameters.status),callback:function ($$v) {_vm.$set(_vm.parameters, "status", $$v)},expression:"parameters.status"}})],1):_vm._e(),_c('div',[_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.tableData},scopedSlots:_vm._u([{key:"5",fn:function(ref){
        var rest = objectWithoutProperties( ref, [] );
        var data = rest;
return [_c('span',{class:[
            String(data.data.value).toUpperCase() === 'VALID'
              ? 'text-green-3'
              : 'text-red-4' ]},[_vm._v(_vm._s(data.data.value.toUpperCase() === 'VALID' ? data.data.value.toUpperCase() : data.data.value))])]}},{key:"data-empty",fn:function(){return [(_vm.fileData.filename)?_c('div',{staticClass:"flex flex-col w-full h-96 gap-4 justify-center items-center"},[_c('EmptyFilterIllustration'),_c('span',{staticClass:"font-medium mt-6"},[_vm._v("Tidak Menemukan Hasil Sesuai Filter")]),_c('span',{staticClass:"font-light text-gray-1"},[_vm._v("Silahkan ubah kata kunci atau reset filter")])],1):(
            _vm.$route.name !==
              _vm.enumManageCourierRoute.HISTORY_UPLOAD_BULKY_DETAIL_PAGE
          )?_c('div',{staticClass:"flex flex-col w-full h-96 gap-4 justify-center items-center"},[_c('EmptyIllustration'),_c('span',{staticClass:"font-semibold"},[_vm._v("Belum Ada Data yang di Upload")]),_c('span',{staticClass:"text-gray-1"},[_vm._v("Silakan upload file excel untuk mengatur hari kerja kurir")]),_c('Button',{attrs:{"data-testid":"managecourier__uploadbulky-uploadexcel-button"},on:{"click":function () {
                _vm.modalUploadBulkyVisible = true
              }}},[_c('span',[_vm._v("Upload Excel")])])],1):_vm._e()]},proxy:true}])})],1),_c('div',[(_vm.fileData.filename !== '')?_c('PaginationNav',{staticClass:"mb-8",attrs:{"page":_vm.parameters.page,"perPage":_vm.parameters.per_page,"enablePerPageOption":true,"perPageOptions":_vm.paginationOptions,"totalItem":_vm.controller.uploadBulkPaginationData.totalItem},on:{"onChangeOption":function (e) { return _vm.onChangePaginationOption(e); },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
          _vm.fetchUploadBulkData()
        })($event)},"input":function () {
          _vm.fetchUploadBulkData()
        }},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}}):_vm._e()],1),_c('ModalUploadBulky',{attrs:{"visible":_vm.modalUploadBulkyVisible},on:{"close":function () {
        _vm.modalUploadBulkyVisible = false
      }}}),_c('ModalConfirm',{attrs:{"visible":_vm.modalConfirmReuploadVisible,"title":"Upload Ulang Data?","description":"Data yang Anda upload tidak akan tersimpan jika melakukan upload ulang","actionBtnLabel":"Upload Ulang"},on:{"cancel":function () {
        _vm.modalConfirmReuploadVisible = false
      },"action":_vm.onConfirmReupload}}),_c('ModalConfirm',{attrs:{"visible":_vm.modalConfirmReturnVisible,"title":"Yakin Ingin Keluar?","description":"Data yang yang Anda upload tidak akan terkirim jika Anda belum menekan tombol Kirim Data","actionBtnLabel":"Keluar","cancelBtnLabel":"Tetap Disini"},on:{"cancel":function () {
        _vm.modalConfirmReturnVisible = false
      },"action":_vm.onConfirmReturn}}),_c('ModalFailed',{attrs:{"visible":_vm.modalFailedConnectionVisible,"title":"Koneksi Internet Bermasalah","description":"Silahkan upload ulang data Anda"}},[_c('div',{attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('Button',{staticClass:"w-full",on:{"click":_vm.onFailedUpload}},[_c('span',[_vm._v("Upload Ulang")])])],1)]),_c('ModalFailed',{attrs:{"visible":_vm.modalFailedInvalidFileVisible,"title":"Format File Tidak Sesuai","description":"Pastikan format file .xlsx & ukuran maksimal 16 MB"}},[_c('div',{attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('Button',{staticClass:"w-full",on:{"click":function($event){return _vm.onConfirmReupload()}}},[_c('span',[_vm._v("Upload Ulang")])])],1)]),_c('Modal',{attrs:{"visible":_vm.modalFailedNameExistVisible,"body":"Nama file sama, silakan upload ulang file dengan nama yang berbeda"}},[_c('div',{attrs:{"slot":"modal-header"},slot:"modal-header"},[_c('ErrorIcon',{staticClass:"my-4"}),_c('span',{staticClass:"text-bgMatterhorn text-sm font-semibold"},[_vm._v("Upload Data Gagal")])],1),_c('div',{attrs:{"slot":"modal-footer"},slot:"modal-footer"},[_c('Button',{staticClass:"w-full py-2-b px-40-a text-xs",attrs:{"customPadding":""},on:{"click":function($event){return _vm.onConfirmReupload()}}},[_vm._v("Upload Ulang")])],1)]),_c('ModalSuccess',{attrs:{"visible":_vm.modalSuccessVisible,"title":"Data Berhasil Dikirim","description":"Silahkan menunggu proses persetujuan data Anda"},on:{"close":function () {
        _vm.$router.push({
          name: _vm.enumManageCourierRoute.HISTORY_UPLOAD_BULKY_LIST_PAGE,
        })
      }}}),(
      _vm.controller.isLoadingSubmitBulk || _vm.controller.isLoadingGetUploadBulkData
    )?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }